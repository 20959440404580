@font-face {
  font-family: TWK-Lausanne;
  src: url(../src/assets/fonts/TWKLausanne-700.ttf);
  font-weight: 700;
  font-feature-settings: "clig" off, "liga" off;
  font-display: swap;
}
@font-face {
  font-family: TWK-Lausanne;
  src: url(../src/assets/fonts/TWKLausanne-400.ttf);
  font-weight: 400;
  font-feature-settings: "clig" off, "liga" off;
  font-display: swap;
}
@font-face {
  font-family: TWK-Lausanne;
  src: url(../src/assets/fonts/TWKLausanne-350.otf);
  font-weight: 350;
  font-feature-settings: "clig" off, "liga";
  font-display: swap;
}

body {
  margin: 0;
  min-height: 100vh;
  display: flex;
  flex-flow: column;
  font-family: Inter-Regular;
  scroll-behavior: auto;
  -moz-osx-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  line-height: normal;
  font-family: TWK-Lausanne;
  color: #1f1f1f;

  /* transition: all 0.2s linear; */
}

.transparent-button {
  color: #1f1f1f;
  text-decoration: none;
}
